import { IQuickPopup, IRootState } from '@/modules/common/common.interface';
import { IToastMessage } from '@/modules/error/error.interface';
import { GetterTree } from 'vuex';

// eslint-disable-next-line import/prefer-default-export
export const getters: GetterTree<IRootState, IRootState> = {
  drawer(state: IRootState): boolean {
    return state.drawer;
  },
  set_drawer(state: IRootState): boolean {
    return state.set_drawer;
  },
  show_loading(state: IRootState): boolean {
    return state.show_loading;
  },
  toasts(state: IRootState): IToastMessage[] {
    return state.toasts;
  },
  popup(state: IRootState): boolean {
    return state.popup;
  },
  popup_type(state: IRootState): string {
    return state.popup_type;
  },
  popup_name(state: IRootState): string {
    return state.popup_name;
  },
  popup_data(state: IRootState): any {
    return state.popup_data;
  },
  is_edit_mode(state: IRootState): boolean {
    return state.is_edit_mode;
  },
  is_add_mode(state: IRootState): boolean {
    return state.is_add_mode;
  },
  is_view_mode(state: IRootState): boolean {
    return state.is_view_mode;
  },
  quick_popup(state: IRootState): boolean {
    return state.quick_popup;
  },
  quick_popup_title(state: IRootState): string {
    return state.quick_popup_title;
  },
  quick_popup_type(state: IRootState): string {
    return state.quick_popup_type;
  },
  quick_popup_data(state: IRootState): any {
    return state.quick_popup_data;
  },
  quick_popup_width(state: IRootState): number {
    return state.quick_popup_width;
  },
  quick_popup_back(state: IRootState): IQuickPopup {
    return state.quick_popup_back;
  },
  authStatus(state: IRootState): string {
    return state.status;
  },
  friendly_error_message(state: IRootState): string {
    return state.friendly_error_message;
  },
  get_date(state: IRootState): object {
    return state.get_date;
  }
};
