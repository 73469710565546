import { ActionContext, ActionTree } from 'vuex';
import { IRootState } from '@/modules/common/common.interface';
import Api from '@/services/api/ApiFactory';
import { ERootMutations } from '@/modules/common/common.enum';
import { ISignUpApi, ISignUpSsoApi } from '@/modules/signup/signup.interface';
import ResourceResponse from '@/services/api/common/ResourceResponse';
import { IAuthedUser } from '@/modules/authentication/auth.interface';
import { ISignUpState } from './types';

const ApiFactory = new Api();

// eslint-disable-next-line import/prefer-default-export
export const actions: ActionTree<ISignUpState, IRootState> = {
  SIGN_UP(
    { commit, dispatch }: ActionContext<ISignUpState, IRootState>,
    payload: ISignUpApi
  ): Promise<ResourceResponse<null>> {
    return new Promise((resolve, reject) => {
      ApiFactory.getSignUp()
        .userSignUp(payload)
        .then((response: ResourceResponse<null>) => {
          commit('SET_SIGN_UP', true);
          resolve(response);
        })
        .catch((errorResponse) => {
          dispatch('THROW_CUSTOM_ERROR', errorResponse, { root: true });
          reject(errorResponse);
        });
    });
  },
  SSO_SIGN_UP(
    { commit, dispatch }: ActionContext<ISignUpState, IRootState>,
    payload: ISignUpSsoApi
  ): Promise<ResourceResponse<IAuthedUser>> {
    return new Promise((resolve, reject) => {
      ApiFactory.getSignUp()
        .ssoSignup(payload)
        .then((response: ResourceResponse<IAuthedUser>) => {
          commit('SET_SIGN_UP', true);
          commit(ERootMutations.SET_AUTH_STATUS_SUCCESS, 'success', { root: true });
          resolve(response);
        })
        .catch((errorResponse) => {
          dispatch('THROW_CUSTOM_ERROR', errorResponse, { root: true });
          reject(errorResponse);
        });
    });
  }
};
