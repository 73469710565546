import { ISystemLookups } from '@/modules/lookup/lookup.interface';
import { TCompanyLookups, TTrackTraceLookups } from '@/modules/lookup/lookup.type';
import { MutationTree } from 'vuex';
import { ILookupState } from './types';

// eslint-disable-next-line import/prefer-default-export
export const mutations: MutationTree<ILookupState> = {
  UPDATE_SYSTEM_LOOKUPS(state: ILookupState, system_lookups: ISystemLookups) {
    state.system_lookups = system_lookups;
  },
  UPDATE_COMPANY_LOOKUPS(state: ILookupState, company_lookups: TCompanyLookups) {
    state.company_lookups = company_lookups;
  },
  UPDATE_TRACK_TRACE_LOOKUPS(state: ILookupState, track_trace_lookups: TTrackTraceLookups) {
    state.track_trace_lookups = track_trace_lookups;
  }
};
