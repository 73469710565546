import { IImageUpload, IImageUploadRequest } from '@/modules/image/image.interface';
import { IChangeProfilePasswordParams } from '@/modules/profile/profile.interface';
import {
  IChangeUserEmail,
  IUpdateUserProfileParams,
  IUserProfile,
  IUserRules
} from '@/modules/user/user.interface';
import ClientInterface from './common/ClientInterface';
import ResourceResponse from './common/ResourceResponse';

export default class Profile {
  private readonly client: ClientInterface;

  private readonly resource: string = 'profile';

  constructor(client: ClientInterface) {
    this.client = client;
  }

  getProfile(): Promise<ResourceResponse<IUserProfile>> {
    return this.client.get(this.resource);
  }

  updateProfile(body: IUpdateUserProfileParams): Promise<ResourceResponse<IUserProfile>> {
    return this.client.patch(this.resource, body);
  }

  changePassword(payload: IChangeProfilePasswordParams): Promise<ResourceResponse<IUserProfile>> {
    return this.client.post(`${this.resource}/change-password`, payload);
  }

  uploadUserProfileImage(body: IImageUploadRequest): Promise<ResourceResponse<IImageUpload>> {
    return this.client.post(`${this.resource}/image-upload`, body);
  }

  changeProfileEmail(body: IChangeUserEmail): Promise<ResourceResponse<IUserProfile>> {
    return this.client.post(`${this.resource}/change-email`, body);
  }

  verifyChangeEmail(payload: string): Promise<ResourceResponse<void>> {
    return this.client.post(`${this.resource}/verify-change-email`, { token: payload });
  }

  getCaslRules(): Promise<IUserRules> {
    return this.client.get(`${this.resource}/casl`);
  }

  switchCompany(payload: string): Promise<ResourceResponse<IUserRules>> {
    return this.client.post(`${this.resource}/switch-company`, { companyId: payload });
  }
}
