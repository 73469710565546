import { ISignUpApi, ISignUpSsoApi } from '@/modules/signup/signup.interface';
import { IAuthedUser } from '@/modules/authentication/auth.interface';
import Client from './common/Client';
import ResourceResponse from './common/ResourceResponse';

export default class Signup {
  private readonly client: Client;

  private readonly resource = 'signup';

  constructor(client: Client) {
    this.client = client;
  }

  userSignUp(payload: ISignUpApi): Promise<ResourceResponse<null>> {
    return this.client.post(`${this.resource}`, payload);
  }

  ssoSignup(payload: ISignUpSsoApi): Promise<ResourceResponse<IAuthedUser>> {
    return this.client.post(`${this.resource}/sso`, payload);
  }
}
