import { IAddApiKey, IApiKey, IUpdateApiKey } from '@/modules/api-key/api-key.interface';
import { IImageUpload, IImageUploadRequest } from '@/modules/image/image.interface';
import {
  IChangeUserPasswordParams,
  ICreateUserParams,
  IUpdateUserParams,
  IUser,
  IUserProfile,
  IUserApi,
  ISearchableUser,
  IUserAllowance
} from '@/modules/user/user.interface';
import { IPaginatedData } from '@/modules/common/pagination.interface';
import ClientInterface from './common/ClientInterface';
import PaginatedResponse from './common/PaginatedResponse';
import PaginationBuilder from './common/PaginationBuilder';
import ResourceResponse from './common/ResourceResponse';

export default class User {
  private readonly client: ClientInterface;

  private readonly resource: string = 'user';

  constructor(client: ClientInterface) {
    this.client = client;
  }

  getPaginatedUsers(
    builder: PaginationBuilder = new PaginationBuilder()
  ): Promise<PaginatedResponse<IPaginatedData<ISearchableUser>>> {
    builder.setResource(this.resource);
    return this.client.getPaginated<IPaginatedData<ISearchableUser>>(builder);
  }

  getUserById(id: string): Promise<ResourceResponse<IUser | null>> {
    return this.client.getById(this.resource, id);
  }

  createUser(body: ICreateUserParams): Promise<ResourceResponse<IUserApi>> {
    return this.client.create<ICreateUserParams, IUserApi>(this.resource, body);
  }

  updateUser(id: string, body: IUpdateUserParams): Promise<ResourceResponse<IUserApi>> {
    return this.client.patch(this.resource, body, id);
  }

  deleteUserById(id: string): Promise<void> {
    return this.client.delete(this.resource, id);
  }

  changeUserPassword(
    id: string,
    payload: IChangeUserPasswordParams
  ): Promise<ResourceResponse<IUserProfile>> {
    return this.client.post(`${this.resource}/${id}/change-password`, payload);
  }

  getUserApiKeys(id: string): Promise<ResourceResponse<IApiKey[]>> {
    return this.client.get(`${this.resource}/${id}/api-key`);
  }

  createUserApiKey(id: string, body: IAddApiKey): Promise<ResourceResponse<IApiKey>> {
    return this.client.create<IAddApiKey, IApiKey>(`${this.resource}/${id}/api-key`, body);
  }

  updateUserApiKey(
    id: string,
    apiKeyId: string,
    body: IUpdateApiKey
  ): Promise<ResourceResponse<IApiKey>> {
    return this.client.patch(`${this.resource}/${id}/api-key/${apiKeyId}`, body);
  }

  deleteUserApiKeyById(userId: string, id: string): Promise<void> {
    return this.client.delete(`${this.resource}/${userId}/api-key`, id);
  }

  uploadUserProfileImage(
    id: string,
    body: IImageUploadRequest
  ): Promise<ResourceResponse<IImageUpload>> {
    return this.client.post(`${this.resource}/${id}/image-upload`, body);
  }

  getAllowance(): Promise<IUserAllowance> {
    return this.client.get(`${this.resource}/allowance`);
  }
}
