import { IRootState } from '@/modules/common/common.interface';
import { ActionContext, ActionTree } from 'vuex';
import Api from '@/services/api/ApiFactory';
import ResourceResponse from '@/services/api/common/ResourceResponse';
import { ISystemLookups } from '@/modules/lookup/lookup.interface';
import { ETrackTraceLookups } from '@/modules/lookup/lookup.enum';
import { TCompanyLookup, TCompanyLookups, TTrackTraceLookups } from '@/modules/lookup/lookup.type';
import { ILookupState } from './types';

const ApiFactory = new Api();

// eslint-disable-next-line import/prefer-default-export
export const actions: ActionTree<ILookupState, IRootState> = {
  GET_SYSTEM_LOOKUPS({
    commit,
    dispatch
  }: ActionContext<ILookupState, IRootState>): Promise<unknown> {
    return new Promise((resolve, reject) => {
      ApiFactory.getLookup()
        .getSystemLookups()
        .then((response: ResourceResponse<ISystemLookups>) => {
          commit('UPDATE_SYSTEM_LOOKUPS', response);
          resolve(response);
        })
        .catch((errorResponse: unknown) => {
          if (errorResponse instanceof Error) {
            dispatch('THROW_CUSTOM_ERROR', errorResponse, { root: true });
            reject(errorResponse);
          }
        });
    });
  },
  GET_COMPANY_LOOKUPS(
    { commit, dispatch }: ActionContext<ILookupState, IRootState>,
    payload: TCompanyLookup[]
  ): Promise<TCompanyLookups> {
    return new Promise((resolve, reject) => {
      ApiFactory.getLookup()
        .getCompanyLookups(payload)
        .then((response: TCompanyLookups) => {
          commit('UPDATE_COMPANY_LOOKUPS', response);
          resolve(response);
        })
        .catch((errorResponse: unknown) => {
          if (errorResponse instanceof Error) {
            dispatch('THROW_CUSTOM_ERROR', errorResponse, { root: true });
            reject(errorResponse);
          }
        });
    });
  },
  GET_TRACK_TRACE_LOOKUPS(
    { commit, dispatch }: ActionContext<ILookupState, IRootState>,
    payload: ETrackTraceLookups[]
  ): Promise<ResourceResponse<TTrackTraceLookups>> {
    return new Promise((resolve, reject) => {
      ApiFactory.getLookup()
        .getTrackTraceLookups(payload)
        .then((response: ResourceResponse<TTrackTraceLookups>) => {
          commit('UPDATE_TRACK_TRACE_LOOKUPS', response);
          resolve(response);
        })
        .catch((errorResponse: unknown) => {
          if (errorResponse instanceof Error) {
            dispatch('THROW_CUSTOM_ERROR', errorResponse, { root: true });
            reject(errorResponse);
          }
        });
    });
  }
};
