import { ICompany, ISearchableCompany } from '@/modules/company/company.interface';
import { MutationTree } from 'vuex';
import { ICompanyState } from './types';

// eslint-disable-next-line import/prefer-default-export
export const mutations: MutationTree<ICompanyState> = {
  UPDATE_PAGINATED_COMPANIES(state: ICompanyState, paginated_companies: ISearchableCompany) {
    state.paginated_companies = paginated_companies;
  },
  SELECTED_COMPANY(state: ICompanyState, selected_company: ICompany) {
    state.selected_company = selected_company;
  },
  UPDATE_SELECTED_COMPANY(state: ICompanyState, updated_selected_company: ICompany) {
    state.updated_selected_company = updated_selected_company;
  },
  CREATED_COMPANY(state: ICompanyState, created_company: ICompany) {
    state.created_company = created_company;
  }
};
