import { EDimensions } from '@/modules/analytics/technology/technology.enum';
import {
  IAnalyticsScanCountsStatResults,
  IAnalyticsScanStat
} from '@/modules/analytics/technology/technology.interface';
import { IRootState } from '@/modules/common/common.interface';
import Api from '@/services/api/ApiFactory';
// eslint-disable-next-line import/order
import { ActionContext, ActionTree } from 'vuex';
import { TechnologyState } from './types';

const ApiFactory = new Api();

// eslint-disable-next-line import/prefer-default-export
export const actions: ActionTree<TechnologyState, IRootState> = {
  GET_TECHNOLOGY_ANALYTICS_SCANS(
    { commit, dispatch }: ActionContext<TechnologyState, IRootState>,
    payload
  ) {
    return new Promise((resolve, reject) => {
      ApiFactory.getTechnology()
        .getAnalyticsScanStats(payload)
        .then((response: IAnalyticsScanStat) => {
          commit('UPDATE_TECHNOLOGY_ANALYTICS_SCANS', response.dimensions);
          resolve(response.dimensions);
        })
        .catch((errorResponse) => {
          dispatch('THROW_CUSTOM_ERROR', errorResponse);
          reject(errorResponse);
        });
    });
  },
  GET_PAGINATED_BROWSER_LIST({ commit, dispatch }, payload) {
    return new Promise((resolve, reject) => {
      ApiFactory.getTechnology()
        .getAnalyticsScanCountsStats(payload, EDimensions.BROWSER)
        .then((response: IAnalyticsScanCountsStatResults) => {
          commit('UPDATE_TECHNOLOGY_BROWSER_LIST', response.data);
          resolve(response);
        })
        .catch((errorResponse) => {
          dispatch('THROW_CUSTOM_ERROR', errorResponse);
          reject(errorResponse);
        });
    });
  },
  GET_PAGINATED_OS_LIST({ commit, dispatch }, payload) {
    return new Promise((resolve, reject) => {
      ApiFactory.getTechnology()
        .getAnalyticsScanCountsStats(payload, EDimensions.OS)
        .then((response: IAnalyticsScanCountsStatResults) => {
          commit('UPDATE_TECHNOLOGY_OS_LIST', response.data);
          resolve(response);
        })
        .catch((errorResponse) => {
          dispatch('THROW_CUSTOM_ERROR', errorResponse);
          reject(errorResponse);
        });
    });
  }
};
