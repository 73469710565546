import { IImageUpload } from '@/modules/image/image.interface';
import { IUserProfile } from '@/modules/user/user.interface';
import { MutationTree } from 'vuex';
import { PackRule } from '@casl/ability/dist/types/extra';
import { RawRule } from '@casl/ability';
import { AbilityTypes } from '@casl/ability/dist/types/types';
import { IProfileState } from './types';

// eslint-disable-next-line import/prefer-default-export
export const mutations: MutationTree<IProfileState> = {
  SELECTED_PROFILE(state: IProfileState, selected_profile: IUserProfile) {
    state.selected_profile = selected_profile;
  },
  UPDATE_SELECTED_PROFILE(state: IProfileState, updated_selected_profile: IUserProfile) {
    state.updated_selected_profile = updated_selected_profile;
  },
  UPDATE_SELECTED_PROFILE_IMAGE(state: IProfileState, update_selected_profile_image: IImageUpload) {
    state.update_selected_profile_image = update_selected_profile_image;
  },
  IS_EMAIL_SUBMITTED(state: IProfileState, is_email_submitted: boolean) {
    state.is_email_submitted = is_email_submitted;
  },
  UPDATED_EMAIL(state: IProfileState, updated_email: string) {
    state.updated_email = updated_email;
  },
  SELECTED_CASL_RULES(
    state: IProfileState,
    selected_casl_rules: PackRule<RawRule<AbilityTypes>>[]
  ) {
    state.selected_casl_rules = selected_casl_rules;
  }
};
