import {
  ICreateFolderParams,
  IMediaFile,
  IMediaFolder,
  IMediaSearchItem,
  IMediaUpload,
  IMediaUploadRequest,
  IUpdateFileParams
} from '@/modules/media-library/media.interface';
import { TDeleteMedia } from '@/modules/media-library/media.type';
import ClientInterface from './common/ClientInterface';
import PaginatedResponse from './common/PaginatedResponse';
import PaginationBuilder from './common/PaginationBuilder';
import ResourceResponse from './common/ResourceResponse';

export default class Media {
  private readonly client: ClientInterface;

  private readonly resource: string = 'v1/media';

  constructor(client: ClientInterface) {
    this.client = client;
  }

  uploadToGetPresignedUrl(body: IMediaUploadRequest): Promise<ResourceResponse<IMediaUpload>> {
    return this.client.post(`${this.resource}/upload`, body);
  }

  getFileById(id: string): Promise<ResourceResponse<IMediaFile | null>> {
    return this.client.getById(`${this.resource}/file`, id);
  }

  updateFile(id: string, body: IUpdateFileParams): Promise<ResourceResponse<IMediaFile>> {
    return this.client.patch<IUpdateFileParams, IMediaFile>(`${this.resource}/file/${id}`, body);
  }

  createFolder(body: ICreateFolderParams): Promise<ResourceResponse<IMediaFolder>> {
    return this.client.create<ICreateFolderParams, IMediaFolder>(`${this.resource}/folder`, body);
  }

  getFolderById(id: string): Promise<ResourceResponse<IMediaFolder | null>> {
    return this.client.getById(`${this.resource}/folder`, id);
  }

  updateFolder(id: string, body: ICreateFolderParams): Promise<ResourceResponse<IMediaFolder>> {
    return this.client.patch<ICreateFolderParams, IMediaFolder>(
      `${this.resource}/folder/${id}`,
      body
    );
  }

  deleteMediaById(payload: TDeleteMedia): Promise<void> {
    return this.client.delete(`${this.resource}/${payload.type}`, payload.id);
  }

  searchMedia(
    builder: PaginationBuilder = new PaginationBuilder()
  ): Promise<PaginatedResponse<IMediaSearchItem>> {
    builder.setResource(`${this.resource}/search`);
    return this.client.getPaginated<IMediaSearchItem>(builder);
  }
}
