import {
  IConfigurableIntegrations,
  TIntegration
} from '@/views/private/integration/types/interface';
import { MutationTree } from 'vuex';
import { ILinkState } from './types';

// eslint-disable-next-line import/prefer-default-export
export const mutations: MutationTree<ILinkState> = {
  UPDATE_INTEGRATION_LIST(state: ILinkState, integration_list: IConfigurableIntegrations) {
    state.integration_list = integration_list;
  },
  UPDATE_SELECTED_INTEGRATION(state: ILinkState, selected_integration: TIntegration) {
    state.selected_integration = selected_integration;
  }
};
