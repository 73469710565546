/* eslint-disable import/prefer-default-export */
import { IRootState } from '@/modules/common/common.interface';
import { EFilterOperators, IPaginatedData } from '@/modules/common/pagination.interface';
import { ILinkShortUrl, ISearchableLink } from '@/modules/qr-generator/link.interface';
import Api from '@/services/api/ApiFactory';
import PaginatedResponse from '@/services/api/common/PaginatedResponse';
import PaginationBuilder from '@/services/api/common/PaginationBuilder';
import ResourceResponse from '@/services/api/common/ResourceResponse';
import { ActionContext, ActionTree } from 'vuex';
import { ICreateShortUrlApi } from '@/views/private/url-shortener/types/interface';
import { IShortUrlState } from './types';

const ApiFactory = new Api();
const builder = new PaginationBuilder();

export const actions: ActionTree<IShortUrlState, IRootState> = {
  GET_PAGINATED_SHORT_URLS(
    { commit, dispatch }: ActionContext<IShortUrlState, IRootState>,
    payload
  ): Promise<PaginatedResponse<IPaginatedData<ISearchableLink>>> {
    return new Promise((resolve, reject) => {
      builder.setPage(payload.page);
      builder.setPageSize(payload.itemsPerPage);
      if (payload?.sort?.field) {
        builder.setSortBy(payload.sort.field, payload.sort.direction);
      }
      builder.setSearch(payload.search ?? '');
      builder.clearFilter();
      if (payload.filter) {
        payload.filter.forEach((element) => {
          builder.addFilter(element.selectedFilter, EFilterOperators.EQ, element.value);
        });
      }
      ApiFactory.getLink()
        .getAllPaginatedLinks(builder)
        .then((response: PaginatedResponse<IPaginatedData<ISearchableLink>>) => {
          commit('UPDATE_PAGINATED_SHORT_URLS', response);
          resolve(response);
        })
        .catch((errorResponse: unknown) => {
          if (errorResponse instanceof Error) {
            dispatch('THROW_CUSTOM_ERROR', errorResponse, { root: true });
            reject(errorResponse);
          }
        });
    });
  },
  GET_SHORT_URL_BY_ID(
    { commit, dispatch }: ActionContext<IShortUrlState, IRootState>,
    id: string
  ): Promise<ILinkShortUrl> {
    return new Promise((resolve, reject) => {
      ApiFactory.getShortUrl()
        .getShortUrlById(id)
        .then((response: ResourceResponse<ILinkShortUrl>) => {
          commit('SELECTED_SHORT_URL', response.resource);
          resolve(response.resource);
        })
        .catch((errorResponse: unknown) => {
          if (errorResponse instanceof Error) {
            dispatch('THROW_CUSTOM_ERROR', errorResponse, { root: true });
            reject(errorResponse);
          }
        });
    });
  },
  CREATE_SHORT_URL(
    { commit, dispatch }: ActionContext<IShortUrlState, IRootState>,
    payload: ICreateShortUrlApi
  ): Promise<ILinkShortUrl> {
    return new Promise((resolve, reject) => {
      ApiFactory.getShortUrl()
        .createShortUrl(payload)
        .then((response: ResourceResponse<ILinkShortUrl>) => {
          commit('UPDATE_SHORT_URL', response.resource);
          resolve(response.resource);
        })
        .catch((errorResponse: unknown) => {
          if (errorResponse instanceof Error) {
            dispatch('THROW_CUSTOM_ERROR', errorResponse, { root: true });
            reject(errorResponse);
          }
        });
    });
  },
  UPDATE_SHORT_URL(
    { commit, dispatch }: ActionContext<IShortUrlState, IRootState>,
    payload: { id: string; body: Partial<ICreateShortUrlApi> }
  ): Promise<ILinkShortUrl> {
    return new Promise((resolve, reject) => {
      ApiFactory.getShortUrl()
        .updateShortUrl(payload.id, payload.body)
        .then((response: ResourceResponse<ILinkShortUrl>) => {
          commit('UPDATE_SHORT_URL', response.resource);
          resolve(response.resource);
        })
        .catch((errorResponse: unknown) => {
          if (errorResponse instanceof Error) {
            dispatch('THROW_CUSTOM_ERROR', errorResponse, { root: true });
            reject(errorResponse);
          }
        });
    });
  },

  DELETE_SHORT_URL_BY_ID(
    { dispatch }: ActionContext<IShortUrlState, IRootState>,
    id: string
  ): Promise<void> {
    return new Promise((resolve, reject) => {
      ApiFactory.getShortUrl()
        .deleteShortUrlById(id)
        .then((response) => {
          resolve(response);
        })
        .catch((errorResponse: unknown) => {
          if (errorResponse instanceof Error) {
            dispatch('THROW_CUSTOM_ERROR', errorResponse, { root: true });
            reject(errorResponse);
          }
        });
    });
  }
};
