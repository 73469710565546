import { EDimensions } from '@/modules/analytics/technology/technology.enum';
import {
  IAnalyticsScanCountsStatResults,
  IAnalyticsScanStat,
  IAnalyticsScanStatQuery
} from '@/modules/analytics/technology/technology.interface';
import ClientInterface from '@/services/api/common/ClientInterface';

export default class Technology {
  private readonly client: ClientInterface;

  private readonly resource: string = 'analytics';

  constructor(client: ClientInterface) {
    this.client = client;
  }

  getAnalyticsScanStats(payload: IAnalyticsScanStatQuery): Promise<IAnalyticsScanStat> {
    return this.client.get(
      `${this.resource}/scan/popular?from=${payload.from}&to=${payload.to}&dimensions=${payload.dimensions}&top=${payload.top}`
    );
  }

  getAnalyticsScanCountsStats(
    payload: IAnalyticsScanStatQuery,
    dimensions: EDimensions
  ): Promise<IAnalyticsScanCountsStatResults> {
    return this.client.get(
      `${this.resource}/scan/report?from=${payload.from}&to=${payload.to}&dimensions=${dimensions}&top=${payload.top}&metrics=visitors&scanType=${payload.scanType}`
    );
  }
}
