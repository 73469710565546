import { IAnalyticsScanTimeSeries } from '@/modules/analytics-report/analytics-report.interface';
import { IPopularProduct } from '@/modules/analytics/analytics/analytics.interface';
import { EGranularity } from '@/modules/common/common.enum';
import { TPayload } from '@/modules/common/common.type';
import { EScanType } from '@/modules/scan/scan.enum';
import { IScanLocationCount, IScanStatCounts } from '@/modules/scan/scan.interface';
import ClientInterface from './common/ClientInterface';
import GeohashScansBuilder from './common/GeohashScansBuilder';
import GeohashScansResponse from './common/GeohashScansResponse';
import ResourceResponse from './common/ResourceResponse';

export default class Tracer {
  private readonly client: ClientInterface;

  private readonly resource: string = 'analytics/scan';

  constructor(client: ClientInterface) {
    this.client = client;
  }

  getAnalyticsStatCounts(payload): Promise<IScanStatCounts> {
    return this.client.get<IScanStatCounts>(
      `${this.resource}/stat-counts?from=${payload.from}&to=${payload.to}&scanType=${payload.scanType}`
    );
  }

  getAnalyticsScanLocations(
    scanType: EScanType,
    builder: GeohashScansBuilder = new GeohashScansBuilder()
  ): Promise<GeohashScansResponse<IScanLocationCount>> {
    builder.setResource(`${this.resource}/locations`);
    builder.setScanType(scanType);
    return this.client.getScansByGeohash<IScanLocationCount>(builder);
  }

  getMostScannedProducts(payload): Promise<IPopularProduct[]> {
    return this.client.get<IPopularProduct[]>(
      `${this.resource}/products?from=${payload.from}&to=${payload.to}&scanType=${payload.scanType}`
    );
  }

  getAnalyticsScanTimeSeries(
    payload: TPayload<EGranularity>
  ): Promise<ResourceResponse<IAnalyticsScanTimeSeries>> {
    return this.client.get(
      `${this.resource}/time-series?from=${payload.from}&to=${payload.to}&granularity=${payload.granularity}&scanType=${payload.scanType}`
    );
  }
}
