import { IPaginatedData } from '@/modules/common/pagination.interface';
import {
  IForm,
  IFormSubmissionLinkResponse,
  ISearchableForm,
  ISearchableFormSubmission
} from '@/modules/form/form.interface';
import ClientInterface from './common/ClientInterface';
import PaginatedResponse from './common/PaginatedResponse';
import PaginationBuilder from './common/PaginationBuilder';
import ResourceResponse from './common/ResourceResponse';

export default class Form {
  private readonly client: ClientInterface;

  private readonly resource: string = 'form';

  constructor(client: ClientInterface) {
    this.client = client;
  }

  createForm(body: Partial<IForm>): Promise<ResourceResponse<IForm>> {
    return this.client.create<Partial<IForm>, IForm>(this.resource, body);
  }

  updateForm(id: string, body: Partial<IForm>): Promise<ResourceResponse<IForm>> {
    return this.client.patch(this.resource, body, id);
  }

  deleteForm(id: string): Promise<void> {
    return this.client.delete(this.resource, id);
  }

  getPaginatedForms(
    builder: PaginationBuilder = new PaginationBuilder()
  ): Promise<PaginatedResponse<IPaginatedData<ISearchableForm>>> {
    builder.setResource(this.resource);
    return this.client.getPaginated<IPaginatedData<ISearchableForm>>(builder);
  }

  getFormById(id: string): Promise<ResourceResponse<IForm | null>> {
    return this.client.getById(this.resource, id);
  }

  getPaginatedFormSubmissions(
    builder: PaginationBuilder = new PaginationBuilder()
  ): Promise<PaginatedResponse<IPaginatedData<ISearchableFormSubmission>>> {
    builder.setResource(`${this.resource}/submission`);
    return this.client.getPaginated<IPaginatedData<ISearchableFormSubmission>>(builder);
  }

  getSubmissionLinksById(id: string): Promise<IFormSubmissionLinkResponse> {
    return this.client.get(`${this.resource}/${id}/submission-link`);
  }
}
