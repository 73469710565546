import { EAiQrResolution, EAiQrStatus } from '@/modules/aiqr/aiqr.enum';
import {
  IAiQrConfig,
  IImageDimensions,
  TAiQrAspectRatio,
  TAiQrResolution,
  TAiQrStatus,
  TAspectRatioMap
} from '@/modules/aiqr/aiqr.interface';

export const defaultAiQrConfig: IAiQrConfig = {
  prompt: '',
  negativePrompt: '',
  preset: 'none',
  resolution: EAiQrResolution.FULL_HD,
  aspectRatio: '1:1',
  qrRatio: 0.6,
  qrPosition: 'center_center',
  qrPattern: 'square'
};

export const loadingStatus: TAiQrStatus[] = [EAiQrStatus.IN_PROGRESS, EAiQrStatus.QUEUED];

export const mapResolution = {
  [EAiQrResolution.FULL_HD]: {
    title: 'Full HD',
    credit: 1
  },
  [EAiQrResolution.ULTRA_HD]: {
    title: 'Ultra HD',
    credit: 4
  }
};

export const resolutionList = [
  {
    title: 'Full HD',
    value: EAiQrResolution.FULL_HD,
    dimension: '1440 x 1440'
  },
  {
    title: 'Ultra HD',
    value: EAiQrResolution.ULTRA_HD,
    dimension: '2880 x 2880'
  }
];

export const checkPluralCredit = (count: number) => (count > 1 ? 'credits' : 'credit');

export const mapPosition = {
  top_left: 'justify-start align-start',
  top_center: 'justify-center align-start',
  top_right: 'justify-end align-start',
  center_left: 'justify-start align-center',
  center_center: 'justify-center align-center',
  center_right: 'justify-end align-center',
  bottom_left: 'justify-start align-end',
  bottom_center: 'justify-center align-end',
  bottom_right: 'justify-end align-end'
};

const aspectRatioMap: TAspectRatioMap = new Map([
  [
    '1:1',
    new Map([
      ['full_hd', { width: 1440, height: 1440 }],
      ['ultra_hd', { width: 2880, height: 2880 }]
    ])
  ],
  [
    '3:4',
    new Map([
      ['full_hd', { width: 1260, height: 1680 }],
      ['ultra_hd', { width: 2520, height: 3360 }]
    ])
  ],
  [
    '4:3',
    new Map([
      ['full_hd', { width: 1680, height: 1260 }],
      ['ultra_hd', { width: 3360, height: 2520 }]
    ])
  ],
  [
    '9:16',
    new Map([
      ['full_hd', { width: 1080, height: 1920 }],
      ['ultra_hd', { width: 2160, height: 3840 }]
    ])
  ],
  [
    '16:9',
    new Map([
      ['full_hd', { width: 1920, height: 1080 }],
      ['ultra_hd', { width: 3840, height: 2160 }]
    ])
  ]
]);

export const getImageDimensions = (
  aspectRatio: TAiQrAspectRatio,
  resolution: TAiQrResolution
): IImageDimensions | undefined => {
  const aspectRatioEntry = aspectRatioMap.get(aspectRatio);
  if (aspectRatioEntry) {
    return aspectRatioEntry.get(resolution);
  }
  return undefined;
};
