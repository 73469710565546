import { IRootState } from '@/modules/common/common.interface';
import { Module } from 'vuex';
import { actions } from './actions';
import { mutations } from './mutations';
import { ILookupState } from './types';

const state: ILookupState = {
  system_lookups: null,
  company_lookups: null,
  track_trace_lookups: null
};

// eslint-disable-next-line import/prefer-default-export
export const lookup: Module<ILookupState, IRootState> = {
  namespaced: true,
  state,
  mutations,
  actions
};
