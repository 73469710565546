import { IAiQr, TAiQrAspectRatio, TAiQrPosition } from '@/modules/aiqr/aiqr.interface';
import {
  ILinkScanOverTimeCount,
  ITopScannedLinkResponse
} from '@/modules/analytics/analytics/analytics.interface';
import {
  ILink,
  ILinkConnectQr,
  ILinkConnectQrAllowance,
  ILinkConnectQrStats,
  ILinkStaticQr,
  ILinkTag,
  ISearchableLink
} from '@/modules/qr-generator/link.interface';
import {
  ILinkScanMetricsAnalytics,
  ILinkScanPopularAnalytics,
  ILinkScanPopularTermsData,
  ILinkScannedAtAnalytics,
  IScanLocationCount
} from '@/modules/scan/scan.interface';
import PaginatedResponse from '@/services/api/common/PaginatedResponse';
import { MutationTree } from 'vuex';
import { ILinkState } from './types';

// eslint-disable-next-line import/prefer-default-export
export const mutations: MutationTree<ILinkState> = {
  UPDATE_PAGINATED_LINKS(state: ILinkState, paginated_links: PaginatedResponse<ISearchableLink>) {
    state.paginated_links = paginated_links;
  },
  SELECTED_CONNECT_QR(state: ILinkState, selected_connect_qr: ILinkConnectQr) {
    state.selected_connect_qr = selected_connect_qr;
  },
  UPDATE_SELECTED_CONNECT_QR(state: ILinkState, updated_connect_qr: ILinkConnectQr) {
    state.updated_connect_qr = updated_connect_qr;
  },
  SELECTED_STATIC_QR(state: ILinkState, selected_static_qr: ILinkStaticQr) {
    state.selected_static_qr = selected_static_qr;
  },
  UPDATE_SELECTED_STATIC_QR(state: ILinkState, updated_static_qr: ILinkStaticQr) {
    state.updated_static_qr = updated_static_qr;
  },
  SELECTED_LINK(state: ILinkState, selected_metadata: ILink) {
    state.selected_link = selected_metadata;
  },
  UPDATE_SELECTED_LINK(state: ILinkState, update_selected_metadata: ILink) {
    state.update_selected_link = update_selected_metadata;
  },
  UPDATE_SCANNED_AT(state: ILinkState, update_scanned_at: ILinkScannedAtAnalytics) {
    state.update_scanned_at = update_scanned_at;
  },
  UPDATE_SCANNED_AT_BY_METRICS(
    state: ILinkState,
    update_scanned_at_by_metrics: ILinkScanMetricsAnalytics
  ) {
    state.update_scanned_at_by_metrics = update_scanned_at_by_metrics;
  },
  UPDATE_SCANNED_AT_BY_LOCATION(
    state: ILinkState,
    update_scanned_at_by_location: IScanLocationCount[]
  ) {
    state.update_scanned_at_by_location = update_scanned_at_by_location;
  },
  UPDATE_SCANNED_AT_BY_LOCATION_COUNTRIES(
    state: ILinkState,
    update_scanned_at_by_location_countries: ILinkScanPopularTermsData[]
  ) {
    state.update_scanned_at_by_location_countries = update_scanned_at_by_location_countries;
  },
  UPDATE_SCAN_TIME_SERIES(state: ILinkState, update_scan_time_series: ILinkScanOverTimeCount) {
    state.update_scan_time_series = update_scan_time_series;
  },
  UPDATE_POPULAR_SCANS_BY_DIMENSIONS(
    state: ILinkState,
    update_popular_scans_by_dimensions: ILinkScanPopularAnalytics
  ) {
    state.update_popular_scans_by_dimensions = update_popular_scans_by_dimensions;
  },
  UPDATE_SCAN_TOP_LINKS(state: ILinkState, update_scan_top_links: ITopScannedLinkResponse[]) {
    state.update_scan_top_links = update_scan_top_links;
  },
  UPDATE_CONNECT_QR_STATS(state: ILinkState, update_connect_qr_stats: ILinkConnectQrStats) {
    state.update_connect_qr_stats = update_connect_qr_stats;
  },
  UPDATE_CONNECT_QR_ALLOWANCE(
    state: ILinkState,
    update_connect_qr_allowance: ILinkConnectQrAllowance
  ) {
    state.update_connect_qr_allowance = update_connect_qr_allowance;
  },
  UPDATE_TAGS(state: ILinkState, update_tags: ILinkTag[]) {
    state.update_tags = update_tags;
  },
  UPDATE_NEWLY_CREATED_QR(state: ILinkState, newly_created_qr: ILinkConnectQr | ILinkStaticQr) {
    state.newly_created_qr = newly_created_qr;
  },
  UPDATE_SELECTED_AI_QR(state: ILinkState, selected_ai_qr: IAiQr) {
    const index = state.selected_ai_qr.findIndex((item) => item.id === selected_ai_qr.id);
    if (index === -1) {
      state.selected_ai_qr?.push(selected_ai_qr);
    } else {
      state.selected_ai_qr?.splice(index, 1, selected_ai_qr);
    }
    state.latest_ai_qr = selected_ai_qr;
  },
  UPDATE_ALL_AI_QR(state: ILinkState, all_ai_qr: IAiQr[]) {
    state.selected_ai_qr = all_ai_qr;
    state.latest_ai_qr = null;
  },
  CLEAR_SELECTED_AI_QR(state: ILinkState) {
    state.selected_ai_qr = [];
  },
  UPDATE_QR_RATIO_VALUE(state: ILinkState, update_qr_ratio_value: number) {
    state.update_qr_ratio_value = update_qr_ratio_value;
  },
  UPDATE_QR_ASPECT_RATIO_VALUE(state: ILinkState, update_qr_aspect_ratio_value: TAiQrAspectRatio) {
    state.update_qr_aspect_ratio_value = update_qr_aspect_ratio_value;
  },
  UPDATE_QR_POSITION_VALUE(state: ILinkState, update_qr_position_value: TAiQrPosition) {
    state.update_qr_position_value = update_qr_position_value;
  },
  UPDATE_BACK_TO_DONE(state: ILinkState, back_to_done: boolean) {
    state.back_to_done = back_to_done;
  },
  UPDATE_SELECTED_QR_NAME(state: ILinkState, update_data: ILinkConnectQr | ILinkStaticQr) {
    const index = state.paginated_links.resources.findIndex((item) => item.id === update_data.id);
    if (index > -1) {
      state.paginated_links.resources[index].name = update_data.name;
    }
  },
  UPDATE_AI_QR_ERROR(state: ILinkState, ai_qr_error: string) {
    state.ai_qr_error = ai_qr_error;
  }
};
