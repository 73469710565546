import { IRootState } from '@/modules/common/common.interface';
import { Module } from 'vuex';
import { actions } from './actions';
import { mutations } from './mutations';
import { AuthenticationState } from './types';

const state: AuthenticationState = {
  authed_user_tokens: null,
  refreshed_auth_token: null,
  default_company_id: null,
  is_default_company_jwt: false
};

// eslint-disable-next-line import/prefer-default-export
export const authentication: Module<AuthenticationState, IRootState> = {
  namespaced: true,
  state,
  actions,
  mutations
};
