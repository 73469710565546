import { MutationTree } from 'vuex';
import { IAnalyticsScanTimeSeries } from '@/modules/analytics-report/analytics-report.interface';
import { IPopularProduct } from '@/modules/analytics/analytics/analytics.interface';
import { IPaginatedData } from '@/modules/common/pagination.interface';
import { IScan, IScanLocationCount, IScanStatCounts } from '@/modules/scan/scan.interface';
import { IMiscState } from './types';

// eslint-disable-next-line import/prefer-default-export
export const mutations: MutationTree<IMiscState> = {
  UPDATE_PAGINATED_MISC_SCAN(state: IMiscState, paginated_misc_scans: IPaginatedData<IScan>) {
    state.paginated_misc_scans = paginated_misc_scans;
  },
  UPDATE_ANALYTICS_STAT_COUNTS(state: IMiscState, analytics_stat_counts: IScanStatCounts) {
    state.analytics_stat_counts = analytics_stat_counts;
  },
  UPDATE_ANALYTICS_SCAN_LOCATIONS(state: IMiscState, analytics_scan_locations: IScanLocationCount) {
    state.analytics_scan_locations = analytics_scan_locations;
  },
  UPDATE_ANALYTICS_MOST_SCANNED_PRODUCTS(
    state: IMiscState,
    analytics_most_scanned_products: IPopularProduct[]
  ) {
    state.analytics_most_scanned_products = analytics_most_scanned_products;
  },
  UPDATE_ANALYTICS_SCAN_TIME_SERIES(
    state: IMiscState,
    analytics_scan_time_series: IAnalyticsScanTimeSeries
  ) {
    state.analytics_scan_time_series = analytics_scan_time_series;
  }
};
