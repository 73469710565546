import { useDefaultCompanyId } from '@/composables/cookies';
import {
  IPaginatedApiResponse,
  IScansByDateParams,
  IScansBymetaApiResponse
} from '@/modules/client/client.interface';
import { TGeohashScansParams, TPaginatedParams } from '@/modules/client/client.type';
import {
  TBoundingBox,
  TFilter,
  TSearchAfter,
  TSearchBy,
  TSortBy
} from '@/modules/common/service.type';
import { EScanStats, EScanType } from '@/modules/scan/scan.enum';
import buildFilter from '@/services/api/common/BuildFilter';
import builderSearchBy from '@/services/api/common/BuildSearchBy';
import ClientInterface from '@/services/api/common/ClientInterface';
import ResourceResponse from '@/services/api/common/ResourceResponse';
import axios, { AxiosInstance, AxiosResponse } from 'axios';
import GeohashScansBuilder from './GeohashScansBuilder';
import GeohashScansResponse from './GeohashScansResponse';
import PaginatedResponse from './PaginatedResponse';
import PaginationBuilder from './PaginationBuilder';
import ScansByDateResponse from './ScansByDateResponse';

export default class Client implements ClientInterface {
  private http: AxiosInstance;

  constructor(baseUrlOrAxios: string | AxiosInstance, hasHeader: boolean = true) {
    if (typeof baseUrlOrAxios === 'string') {
      const defaultCompanyId = useDefaultCompanyId();
      this.http = axios.create({ baseURL: baseUrlOrAxios, withCredentials: true });

      if (hasHeader && defaultCompanyId.value) {
        this.http.interceptors.request.use(
          (response) => {
            const config = response;
            config.headers['x-company-id'] = defaultCompanyId.value;
            return config;
          },
          (error) => Promise.reject(error)
        );
      }
    } else {
      this.http = baseUrlOrAxios;
    }
  }

  get<Res>(path: string): Promise<Res> {
    return this.http.get<Res>(path).then((response) => response.data);
  }

  post<Req, Res>(path: string, body?: Req): Promise<ResourceResponse<Res>> {
    return this.http.post<Req, AxiosResponse<Res>>(path, body).then(({ data, status }) => ({
      status,
      resource: data
    }));
  }

  patch<Req, Res>(
    resource: string,
    data: Req,
    id?: string | number
  ): Promise<ResourceResponse<Res>> {
    let resourceUrl = `${resource}`;
    if (id) {
      resourceUrl = `${resource}/${id}`;
    }
    return this.http
      .patch<Req, AxiosResponse<Res>>(`${resourceUrl}`, data)
      .then(
        (response): ResourceResponse<Res> =>
          new ResourceResponse<Res>(response.status, response.data)
      );
  }

  create<Req, Res>(resource: string, data: Req): Promise<ResourceResponse<Res>> {
    return this.http
      .post<Req, AxiosResponse<Res>>(resource, data)
      .then(
        (response): ResourceResponse<Res> =>
          new ResourceResponse<Res>(response.status, response.data)
      );
  }

  delete<Req, Res>(resource: string, id?: string | number): Promise<Res> {
    let resourceUrl = `${resource}`;
    if (id) {
      resourceUrl = `${resource}/${id}`;
    }
    return this.http
      .delete<Req, AxiosResponse<Res>>(`${resourceUrl}`)
      .then((response) => response.data);
  }

  getById<Res>(resource: string, id: string | number): Promise<ResourceResponse<Res>> {
    return this.http
      .get<Res>(`${resource}/${id}`)
      .then(
        (response): ResourceResponse<Res> =>
          new ResourceResponse<Res>(response.status, response.data)
      );
  }

  getPaginated<Res>(
    resourceOrBuilder: string | PaginationBuilder,
    search?: string,
    searchBy?: TSearchBy,
    filter?: TFilter,
    sortBy?: TSortBy,
    from?: Date,
    to?: Date,
    page?: number,
    pageSize?: number,
    scanType?: EScanType,
    stats?: EScanStats
  ): Promise<PaginatedResponse<Res>> {
    const params: TPaginatedParams = {};
    let resource;
    if (resourceOrBuilder instanceof PaginationBuilder) {
      const builder: PaginationBuilder = resourceOrBuilder;
      resource = builder.getResource();
      params.from = builder.getFromDate();
      params.to = builder.getToDate();
      params.page = builder.getPage();
      params.limit = builder.getPageSize();
      params.scanType = builder.getScanType();
      params.stats = builder.getScanStats();
      const tmpFilter = buildFilter(builder);
      Object.keys(tmpFilter).forEach((key) => {
        params[`filter.${key}`] = tmpFilter[key];
      });
      params.searchBy = builderSearchBy(builder);
      if (builder.getSearch()) {
        params.search = builder.getSearch();
      }
      if (builder.getSortBy()) {
        params.sortBy = `${builder.getSortBy().field}:${builder.getSortBy().direction}`;
      }
    } else {
      resource = resourceOrBuilder;
      if (search) {
        params.search = search;
      }
      if (searchBy) {
        params.searchBy = {
          ...searchBy
        };
      }
      if (filter) {
        params.filter = {
          ...filter
        };
      }
      if (sortBy) {
        params.sortBy = `${sortBy[0]}:${sortBy[1]}`;
      }
      if (from) {
        params.from = from;
      }
      if (to) {
        params.to = to;
      }
      if (page) {
        params.page = page;
      }
      if (pageSize) {
        params.limit = pageSize;
      }
      if (scanType) {
        params.scanType = scanType;
      }
      if (stats) {
        params.stats = stats;
      }
    }
    return this.http
      .get<IPaginatedApiResponse<Res>>(resource, { params })
      .then(
        (response): PaginatedResponse<Res> =>
          new PaginatedResponse<Res>(
            response.status,
            response.data.data,
            response.data.meta,
            response.data.links
          )
      );
  }

  update<Req, Res>(
    resource: string,
    id: string | number,
    data: Req
  ): Promise<ResourceResponse<Res>> {
    return this.http
      .put<Req, AxiosResponse<Res>>(`${resource}/${id}`, data)
      .then(
        (response): ResourceResponse<Res> =>
          new ResourceResponse<Res>(response.status, response.data)
      );
  }

  getScansByGeohash<Res>(
    resourceOrBuilder: string | GeohashScansBuilder,
    from?: Date,
    to?: Date,
    precision?: number,
    boundingBox?: TBoundingBox,
    scanType?: string
  ): Promise<GeohashScansResponse<Res>> {
    const params: TGeohashScansParams = {};
    let resource;
    if (resourceOrBuilder instanceof GeohashScansBuilder) {
      const builder: GeohashScansBuilder = resourceOrBuilder;
      resource = builder.getResource();
      params.from = builder.getFromDate();
      params.to = builder.getToDate();
      params.precision = builder.getPrecision();
      params.boundingBox = builder.getBoundingBox();
      params.scanType = builder.getScanType();

      if (from) {
        params.from = from;
      }

      if (to) {
        params.to = to;
      }

      if (precision) {
        params.precision = precision;
      }

      if (boundingBox) {
        params.boundingBox = boundingBox;
      }

      if (scanType) {
        params.scanType = scanType;
      }
    }
    return this.http
      .get<Res[]>(resource, { params })
      .then((response) => new GeohashScansResponse<Res>(response.data));
  }

  getScansByDate<Res>(
    resourceOrBuilder: string | GeohashScansBuilder,
    from?: Date,
    to?: Date,
    page?: number,
    pageSize?: number,
    searchAfter?: TSearchAfter
  ): Promise<ScansByDateResponse<Res>> {
    const params: IScansByDateParams = {};
    let resource;
    if (resourceOrBuilder instanceof GeohashScansBuilder) {
      const builder: GeohashScansBuilder = resourceOrBuilder;
      resource = builder.getResource();
      params.from = builder.getFromDate();
      params.to = builder.getToDate();
      params.page = builder.getPage();
      params.limit = builder.getPageSize();
      params.searchAfter = builder.getSearchAfter();

      if (from) {
        params.from = from;
      }

      if (to) {
        params.to = to;
      }

      if (page) {
        params.page = page;
      }

      if (pageSize) {
        params.limit = pageSize;
      }

      if (searchAfter) {
        params.searchAfter = searchAfter;
      }
    }
    return this.http
      .get<IScansBymetaApiResponse<Res>>(resource, { params })
      .then(
        (response): ScansByDateResponse<Res> =>
          new ScansByDateResponse<Res>(response.data.data, response.data.meta)
      );
  }
}
