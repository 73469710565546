import '@/helpers/VuetifyCss';
import { PureAbility } from '@casl/ability';
import { abilitiesPlugin } from '@casl/vue';
import { createApp } from 'vue';
import VueDOMPurifyHTML from 'vue-dompurify-html';
import VueGoogleCharts from 'vue-google-charts';
import VueSvgInlinePlugin from 'vue-svg-inline-plugin';
// eslint-disable-next-line import/no-extraneous-dependencies
import 'vue-svg-inline-plugin/src/polyfills';
// eslint-disable-next-line import/no-extraneous-dependencies
import * as Sentry from '@sentry/vue';
// eslint-disable-next-line import/no-extraneous-dependencies
import { createGtm } from '@gtm-support/vue-gtm';
import { createHead } from '@unhead/vue';
// eslint-disable-next-line import/no-extraneous-dependencies
import { MotionPlugin } from '@vueuse/motion';
import App from './App.vue';
import './plugins/vee-validate';
import vuetify from './plugins/vuetify';
import './registerServiceWorker';
import router from './router';
import { store } from './store';

const ability = new PureAbility();
const app = createApp(App);
const head = createHead();
app.use(head);
app.use(VueDOMPurifyHTML, {
  hooks: {
    beforeSanitizeAttributes: (node) => {
      if (node.tagName === 'A') {
        if (node.hasAttribute('target')) {
          node.setAttribute('data-temp-href-target', node.getAttribute('target'));
        }
      }
    },
    afterSanitizeAttributes: (node) => {
      if (node.tagName === 'A' && node.hasAttribute('data-temp-href-target')) {
        node.setAttribute('target', node.getAttribute('data-temp-href-target'));
        node.removeAttribute('data-temp-href-target');
        if (node.getAttribute('target') === '_blank') {
          node.setAttribute('rel', 'noopener noreferrer');
        }
      }
    }
  }
});
app.use(VueGoogleCharts);
app.use(router);
app.use(store);
app.use(vuetify);
app.use(abilitiesPlugin, ability);
app.use(VueSvgInlinePlugin);
app.use(
  createGtm({
    id: 'GTM-KT5L7XKC',
    vueRouter: router
  })
);
Sentry.init({
  app,
  dsn: process.env.NODE_ENV !== 'development' ? import.meta.env.VITE_APP_SENTRY_DSN : undefined,
  environment: import.meta.env.MODE,
  release: process.env.NODE_ENV !== 'development' ? `Connect ${process.env.GITHUB_SHA}` : undefined,
  integrations: [
    new Sentry.BrowserTracing({
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: [
        /^https:\/\/dashboard.staging.connectqr\.ai\/api/,
        /^https:\/\/dashboard.connectqr\.ai\/api/
      ]
    }),
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false
    })
  ],
  // Performance Monitoring
  tracesSampleRate: 0.5, //  Capture 50% of the transactions
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0 // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  // trackComponents: true
});
app.use(MotionPlugin);
app.mount('#app');
