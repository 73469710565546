import { IPaginatedData } from '@/modules/common/pagination.interface';
import {
  IContainerContentItemInfo,
  IContainerTransactionContentItem
} from '@/modules/tracktrace/container-content';
import {
  IContainerContentChanges,
  IContainerTransactionContent
} from '@/modules/tracktrace/container-content-update';
import {
  IContainerProductDetails,
  IContainerWithContent
} from '@/modules/tracktrace/container.interface';
import { ICustomerOrderActionsResponse } from '@/modules/tracktrace/order-actions';
import { ICustomerOrderItemResponse } from '@/modules/tracktrace/order.interface';
import {
  IContainer,
  IContainerSearchItem,
  IContainerContentTransactionItem,
  IContainerType,
  ICustomer,
  ICustomerOrder,
  ICustomerOrderItem,
  ISearchableContainer,
  ISearchableContainerOrder,
  ISearchableCustomer,
  ISearchableCustomerOrder,
  ISearchableSite,
  ISite
} from '@/modules/tracktrace/tracktrace.interface';
import { MutationTree } from 'vuex';
import { IContainerProductHistoryItem } from '@/modules/tracktrace/container-product-history.interface';
import { ITrackTraceState } from './types';

// eslint-disable-next-line import/prefer-default-export
export const mutations: MutationTree<ITrackTraceState> = {
  SELECTED_CONTAINER(state: ITrackTraceState, selected_container: IContainerWithContent) {
    state.selected_container = selected_container;
  },
  SELECTED_CONTAINER_DESCRIPTION(
    state: ITrackTraceState,
    selected_container_description: IContainer
  ) {
    state.selected_container_description = selected_container_description;
  },
  CONTAINER_UNLOCK_REQUEST_ID(state: ITrackTraceState, container_unlock_request_id: string) {
    state.container_unlock_request_id = container_unlock_request_id;
  },
  UPDATE_PAGINATED_CONTAINER(
    state: ITrackTraceState,
    paginated_container: IPaginatedData<ISearchableContainer>
  ) {
    state.paginated_container = paginated_container;
  },
  UPDATE_ITEM_IN_CONTAINER(
    state: ITrackTraceState,
    update_item_in_container: IContainerContentItemInfo
  ) {
    state.update_item_in_container = update_item_in_container;
  },
  UPDATE_CONTAINER_TRANSACTION_CONTENT(
    state: ITrackTraceState,
    update_container_transaction_content: IContainerTransactionContent
  ) {
    state.update_container_transaction_content = update_container_transaction_content;
  },
  UPDATE_TRANSACT_ITEM_IN_CONTAINER(
    state: ITrackTraceState,
    update_transact_item_in_container: {
      change: IContainerContentTransactionItem;
      content: IContainerTransactionContentItem[];
    }
  ) {
    state.update_transact_item_in_container = update_transact_item_in_container;
  },
  SELECTED_BULK_CONTAINER(state: ITrackTraceState, selected_bulk_container: IContainer[]) {
    state.selected_bulk_container = selected_bulk_container;
  },
  UPDATE_ALL_SORTED_CONTAINER_TYPE(
    state: ITrackTraceState,
    all_sorted_container_type: IContainerType[]
  ) {
    state.all_sorted_container_type = all_sorted_container_type;
  },
  UPDATE_CONTAINER_CHANGES(
    state: ITrackTraceState,
    update_container_changes: IContainerContentChanges
  ) {
    state.update_container_changes = update_container_changes;
  },
  RESTORE_CONTAINER_ITEMS(
    state: ITrackTraceState,
    restore_container_items: IContainerContentChanges
  ) {
    state.restore_container_items = restore_container_items;
  },
  TRANSFER_CONTAINER_LIST(state: ITrackTraceState, transfer_container: IContainer) {
    state.transfer_container_list.push(transfer_container);
  },
  CLEAR_TRANSFER_CONTAINER_LIST(state: ITrackTraceState) {
    state.transfer_container_list = [];
  },
  SELECTED_CONTAINER_TYPE(state: ITrackTraceState, selected_container_type: IContainerType) {
    state.selected_container_type = selected_container_type;
  },
  UPDATE_SELECTED_CONTAINER_TYPE(
    state: ITrackTraceState,
    update_selected_container_type: IContainerType
  ) {
    state.update_selected_container_type = update_selected_container_type;
  },
  UPDATE_PAGINATED_CUSTOMER(
    state: ITrackTraceState,
    paginated_customer: IPaginatedData<ISearchableCustomer>
  ) {
    state.paginated_customer = paginated_customer;
  },
  SELECTED_CUSTOMER(state: ITrackTraceState, selected_customer: ICustomer) {
    state.selected_customer = selected_customer;
  },
  UPDATE_SELECTED_CUSTOMER(state: ITrackTraceState, update_selected_customer: ICustomer) {
    state.update_selected_customer = update_selected_customer;
  },
  UPDATE_PAGINATED_SITE(state: ITrackTraceState, paginated_site: IPaginatedData<ISearchableSite>) {
    state.paginated_site = paginated_site;
  },
  SELECTED_SITE(state: ITrackTraceState, selected_site: ISite) {
    state.selected_site = selected_site;
  },
  UPDATE_SELECTED_SITE(state: ITrackTraceState, update_selected_site: ISite) {
    state.update_selected_site = update_selected_site;
  },
  UPDATE_PAGINATED_CUSTOMER_ORDER(
    state: ITrackTraceState,
    paginated_customer_order: IPaginatedData<ISearchableCustomerOrder>
  ) {
    state.paginated_customer_order = paginated_customer_order;
  },
  SELECTED_CUSTOMER_ORDER(state: ITrackTraceState, selected_customer_order: ICustomerOrder) {
    state.selected_customer_order = selected_customer_order;
  },
  UPDATE_CUSTOMER_ORDER(state: ITrackTraceState, update_customer_order: ICustomerOrder) {
    state.update_customer_order = update_customer_order;
  },
  VALIDATED_CONTAINER(state: ITrackTraceState, validated_container: IContainer) {
    state.validated_container = validated_container;
  },
  UPDATE_SELECTED_CUSTOMER_ORDER_ITEM(
    state: ITrackTraceState,
    update_selected_customer_order_item: ICustomerOrderItem
  ) {
    state.update_selected_customer_order_item = update_selected_customer_order_item;
  },
  UPDATE_ALL_CUSTOMER_ORDER_ITEMS(
    state: ITrackTraceState,
    all_customer_order_items: ICustomerOrderItemResponse
  ) {
    state.all_customer_order_items = all_customer_order_items;
  },
  CUSTOMER_ORDER_RESTORE_LIST(
    state: ITrackTraceState,
    customer_order_restore_list: ICustomerOrderItemResponse
  ) {
    state.customer_order_restore_list = customer_order_restore_list;
  },
  UPDATE_CUSTOMER_ORDER_ACTIONS(
    state: ITrackTraceState,
    customer_order_actions: ICustomerOrderActionsResponse
  ) {
    state.customer_order_actions = customer_order_actions;
  },
  SELECTED_CUSTOMER_ORDER_ACTION(
    state: ITrackTraceState,
    selected_customer_order_action: ICustomerOrder
  ) {
    state.selected_customer_order_action = selected_customer_order_action;
  },
  UPDATE_PAGINATED_CONTAINER_ORDER(
    state: ITrackTraceState,
    paginated_container_order: IPaginatedData<ISearchableContainerOrder>
  ) {
    state.paginated_container_order = paginated_container_order;
  },
  UPDATE_SEARCH_CONTAINER_AND_PRODUCT(
    state: ITrackTraceState,
    search_container_and_product: IContainerSearchItem
  ) {
    state.search_container_and_product = search_container_and_product;
  },
  UPDATE_CONTAINER_HISTORY(
    state: ITrackTraceState,
    container_history: IContainerProductHistoryItem[]
  ) {
    state.container_history = container_history;
  },
  UPDATE_CONTAINER_PRODUCT_DETAILS(
    state: ITrackTraceState,
    container_product_details: IContainerProductDetails
  ) {
    state.container_product_details = container_product_details;
  }
};
