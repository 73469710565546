import { IPaginatedData } from '@/modules/common/pagination.interface';
import {
  IContainerContentItem,
  IContainerContentItemInfo,
  IContainerTransactionContentItem
} from '@/modules/tracktrace/container-content';
import {
  IContainerContentChanges,
  IContainerTransactionContent
} from '@/modules/tracktrace/container-content-update';
import {
  IContainerDetails,
  IContainerProductDetails,
  IContainerWithContent,
  ICreateContainerBatchApi,
  IUpdateContainerApi
} from '@/modules/tracktrace/container.interface';
import {
  ICustomerOrderActionsResponse,
  IOrderChangeStatus
} from '@/modules/tracktrace/order-actions';
import {
  ICustomerOrderItemResponse,
  IOrderContainerItem
} from '@/modules/tracktrace/order.interface';
import {
  IContainer,
  IContainerContentTransactionItem,
  IContainerOrder,
  IContainerOrderDownloadResponse,
  IContainerSearchItem,
  IContainerType,
  ICreateContainer,
  ICreateContainerOrder,
  ICreateContainerType,
  ICreateContainerTypeModified,
  ICreateCustomer,
  ICreateCustomerOrder,
  ICreateCustomerOrderItem,
  ICreateSite,
  ICustomer,
  ICustomerOrder,
  ICustomerOrderItem,
  ISearchableContainer,
  ISearchableContainerOrder,
  ISearchableCustomer,
  ISearchableCustomerOrder,
  ISearchableSite,
  ISite
} from '@/modules/tracktrace/tracktrace.interface';
import { TTechBatchAction } from '@/modules/tech-batch/tech-batch.interface';
import { IContainerProductHistoryApiResponse } from '@/modules/tracktrace/container-product-history.interface';
import ClientInterface from './common/ClientInterface';
import PaginatedResponse from './common/PaginatedResponse';
import PaginationBuilder from './common/PaginationBuilder';
import ResourceResponse from './common/ResourceResponse';

export default class TrackTrace {
  private readonly client: ClientInterface;

  private readonly resource: string = 'tracktrace';

  constructor(client: ClientInterface) {
    this.client = client;
  }

  createContainer(body: ICreateContainer): Promise<ResourceResponse<IContainer>> {
    return this.client.create<ICreateContainer, IContainer>(`${this.resource}/container`, body);
  }

  getContainerById(id: string): Promise<ResourceResponse<IContainerWithContent>> {
    return this.client.getById(`${this.resource}/container`, id);
  }

  updateContainer(id: string, body: IUpdateContainerApi): Promise<ResourceResponse<IContainer>> {
    return this.client.patch(`${this.resource}/container`, body, id);
  }

  lockOrUnlockContainer(
    containerId: string,
    type: string = 'lock'
  ): Promise<ResourceResponse<IContainer>> {
    return this.client.post(`${this.resource}/container/${containerId}/${type}`);
  }

  addItemToContainer(
    containerId: string,
    code: string
  ): Promise<ResourceResponse<IContainerContentItemInfo>> {
    const body = {
      code
    };
    return this.client.post(`${this.resource}/container/${containerId}/add`, body);
  }

  removeItemFromContainer(
    containerId: string,
    typeAndId: IContainerContentItem
  ): Promise<ResourceResponse<void>> {
    return this.client.post(`${this.resource}/container/${containerId}/remove`, typeAndId);
  }

  transactionContent(containerId: string): Promise<IContainerTransactionContent> {
    return this.client.get(`${this.resource}/container/${containerId}/transaction-content`);
  }

  transactItem(
    containerId: string,
    code: string
  ): Promise<
    ResourceResponse<{
      change: IContainerContentTransactionItem;
      content: IContainerTransactionContentItem[];
    }>
  > {
    const body = {
      code
    };
    return this.client.post(`${this.resource}/container/${containerId}/transact-item`, body);
  }

  deleteContainerById(id: string): Promise<void> {
    return this.client.delete(`${this.resource}/container`, id);
  }

  getContainerChanges(containerId: string): Promise<ResourceResponse<IContainerContentChanges>> {
    return this.client.get(`${this.resource}/container/${containerId}/changes`);
  }

  getPaginatedContainers(
    builder: PaginationBuilder = new PaginationBuilder()
  ): Promise<PaginatedResponse<IPaginatedData<ISearchableContainer>>> {
    builder.setResource(`${this.resource}/container`);
    return this.client.getPaginated<IPaginatedData<ISearchableContainer>>(builder);
  }

  createBulkContainer(body: ICreateContainerBatchApi): Promise<ResourceResponse<IContainer[]>> {
    return this.client.create<ICreateContainerBatchApi, IContainer[]>(
      `${this.resource}/container`,
      body
    );
  }

  checkinOrCheckoutContainer(
    containerId: string,
    siteId: string,
    type: string
  ): Promise<ResourceResponse<IContainer>> {
    return this.client.post(`${this.resource}/container/${containerId}/${type}`, { siteId });
  }

  createContainerType(body: ICreateContainerType): Promise<ResourceResponse<IContainerType>> {
    return this.client.create<ICreateContainerType, IContainerType>(
      `${this.resource}/containertype`,
      body
    );
  }

  getAllSortedContainerType(): Promise<ResourceResponse<IContainerType[]>> {
    return this.client.get(`${this.resource}/containertype`);
  }

  getContainerTypeById(id: string): Promise<ResourceResponse<IContainerType | null>> {
    return this.client.getById(`${this.resource}/containertype`, id);
  }

  updateContainerType(
    id: string,
    body: ICreateContainerTypeModified
  ): Promise<ResourceResponse<IContainerType>> {
    return this.client.patch<ICreateContainerTypeModified, IContainerType>(
      `${this.resource}/containertype`,
      body,
      id
    );
  }

  deleteContainerTypeById(id: string): Promise<void> {
    return this.client.delete(`${this.resource}/containertype`, id);
  }

  getPaginatedCustomers(
    builder: PaginationBuilder = new PaginationBuilder()
  ): Promise<PaginatedResponse<IPaginatedData<ISearchableCustomer>>> {
    builder.setResource(`${this.resource}/customer`);
    return this.client.getPaginated<IPaginatedData<ISearchableCustomer>>(builder);
  }

  createCustomer(body: ICreateCustomer): Promise<ResourceResponse<ICustomer>> {
    return this.client.create<ICreateCustomer, ICustomer>(`${this.resource}/customer`, body);
  }

  getCustomerById(id: string): Promise<ResourceResponse<ICustomer | null>> {
    return this.client.getById(`${this.resource}/customer`, id);
  }

  updateCustomer(id: string, body: Partial<ICreateCustomer>): Promise<ResourceResponse<ICustomer>> {
    return this.client.patch<Partial<ICreateCustomer>, ICustomer>(
      `${this.resource}/customer`,
      body,
      id
    );
  }

  deleteCustomerById(id: string): Promise<void> {
    return this.client.delete(`${this.resource}/customer`, id);
  }

  getPaginatedSites(
    builder: PaginationBuilder = new PaginationBuilder()
  ): Promise<PaginatedResponse<IPaginatedData<ISearchableSite>>> {
    builder.setResource(`${this.resource}/site`);
    return this.client.getPaginated<IPaginatedData<ISearchableSite>>(builder);
  }

  createSite(body: ICreateSite): Promise<ResourceResponse<ISite>> {
    return this.client.create<ICreateSite, ISite>(`${this.resource}/site`, body);
  }

  getSiteById(id: string): Promise<ResourceResponse<ISite | null>> {
    return this.client.getById(`${this.resource}/site`, id);
  }

  updateSite(id: string, body: Partial<ICreateSite>): Promise<ResourceResponse<ISite>> {
    return this.client.patch<Partial<ICreateSite>, ISite>(`${this.resource}/site`, body, id);
  }

  deleteSiteById(id: string): Promise<void> {
    return this.client.delete(`${this.resource}/site`, id);
  }

  getPaginatedCustomerOrder(
    builder: PaginationBuilder = new PaginationBuilder()
  ): Promise<PaginatedResponse<IPaginatedData<ISearchableCustomerOrder>>> {
    builder.setResource(`${this.resource}/order`);
    return this.client.getPaginated<IPaginatedData<ISearchableCustomerOrder>>(builder);
  }

  createCustomerOrder(body: ICreateCustomerOrder): Promise<ResourceResponse<ICustomerOrder>> {
    return this.client.create<ICreateCustomerOrder, ICustomerOrder>(`${this.resource}/order`, body);
  }

  getCustomerOrderById(id: string): Promise<ResourceResponse<ICustomerOrder | null>> {
    return this.client.getById(`${this.resource}/order`, id);
  }

  validateContainer(orderId: string, containerCode: string): Promise<ResourceResponse<IContainer>> {
    return this.client.get(`${this.resource}/order/${orderId}/add/${containerCode}`);
  }

  addContainerItemToCustomerOrder(
    payload: Partial<ICreateCustomerOrderItem>
  ): Promise<ResourceResponse<ICustomerOrderItem>> {
    return this.client.post(`${this.resource}/order/${payload.customerOrderId}/item`, payload);
  }

  deleteCustomerOrderItemById(customerOrderId: string, customerOrderItemId: string): Promise<void> {
    return this.client.delete(
      `${this.resource}/order/${customerOrderId}/item`,
      customerOrderItemId
    );
  }

  getAllCustomerOrderItems(
    customerOrderId: string
  ): Promise<ResourceResponse<ICustomerOrderItemResponse>> {
    return this.client.get(`${this.resource}/order/${customerOrderId}/item`);
  }

  getCustomerOrderActions(
    customerOrderId: string
  ): Promise<ResourceResponse<ICustomerOrderActionsResponse>> {
    return this.client.get(`${this.resource}/order/${customerOrderId}/action`);
  }

  performCustomerOrderAction(
    customerOrderId: string,
    request: IOrderChangeStatus
  ): Promise<ResourceResponse<ICustomerOrder>> {
    return this.client.post(`${this.resource}/order/${customerOrderId}/action`, request);
  }

  activateCustomerOrderItemById(
    customerOrderId: string,
    customerOrderItemId: string,
    action: string
  ): Promise<ResourceResponse<IOrderContainerItem>> {
    return this.client.post(
      `${this.resource}/order/${customerOrderId}/item/${customerOrderItemId}/${action}`
    );
  }

  getPaginatedContainerOrders(
    builder: PaginationBuilder = new PaginationBuilder()
  ): Promise<PaginatedResponse<IPaginatedData<ISearchableContainerOrder>>> {
    builder.setResource(`${this.resource}/container-order`);
    return this.client.getPaginated<IPaginatedData<ISearchableContainerOrder>>(builder);
  }

  createContainerOrder(body: ICreateContainerOrder): Promise<ResourceResponse<IContainerOrder>> {
    return this.client.create<ICreateContainerOrder, IContainerOrder>(
      `${this.resource}/container-order`,
      body
    );
  }

  getContainerOrderById(id: string): Promise<ResourceResponse<IContainerOrder>> {
    return this.client.getById(`${this.resource}/container-order`, id);
  }

  unlockContainerRequest(containerId: string): Promise<ResourceResponse<IContainerDetails>> {
    return this.client.get(`${this.resource}/container/${containerId}/unlock-request`);
  }

  getContainerOrderDownloadUrlByAction(
    id: string,
    action: TTechBatchAction
  ): Promise<IContainerOrderDownloadResponse> {
    return this.client.get(`${this.resource}/container-order/${id}/download/${action}`);
  }

  searchContainerAndProduct(code: string): Promise<IContainerSearchItem> {
    return this.client.get(`${this.resource}/search-code?code=${code}`);
  }

  getContainerHistory(id: string): Promise<IContainerProductHistoryApiResponse> {
    return this.client.get(`${this.resource}/product/${id}/history`);
  }

  getContainerProductDetails(id: string): Promise<IContainerProductDetails> {
    return this.client.get(`${this.resource}/product/${id}`);
  }
}
