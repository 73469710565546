import '@mdi/font/css/materialdesignicons.css';
import { useCookies } from '@vueuse/integrations/useCookies';
import { createVuetify } from 'vuetify';
import { aliases, mdi } from 'vuetify/iconsets/mdi';
import 'vuetify/styles';

const host: string = window?.location?.hostname;
const cookies = useCookies(['locale']);
const isEcocanHost = cookies?.get('is-ecocan-host');
let primaryColor = '#70A838';
if (host.indexOf('bsidigitrace.com') > -1) {
  primaryColor = '#D72D18';
}
if (host.indexOf('ecocanafrica.com') > -1 || isEcocanHost) {
  primaryColor = '#228b22';
}

export default createVuetify({
  icons: {
    defaultSet: 'mdi',
    aliases,
    sets: {
      mdi
    }
  },
  theme: {
    defaultTheme: 'light',
    themes: {
      light: {
        dark: false,
        colors: {
          primary: primaryColor,
          greendecrease: '#3DD598',
          reddecrease: '#FC5A5A',
          error: '#D72D18',
          'bsi-red': '#D72D18',
          'sheen-green-lighten-4': '#F2F9EC',
          'negative-red-lighten-4': '#FBEAE8',
          'honey-yellow': '#FDBC11',
          'honey-yellow-lighten-4': '#FDF5EB',
          'royal-blue-lighten-4': '#ECF0F9',
          'light-green': '#E2EED5',
          'tangerine-red': '#F05C2B',
          'ecocan-dark-green': '#094c31',
          'ecocan-dark-gray': '#373a35',
          'ecocan-light-gray': '#094c31',
          'ecocan-light-green': '#b9f98f'
        }
      },
      dark: {
        dark: true,
        colors: {
          background: '#FFFFFF',
          primary: '#70A838'
        }
      }
    }
  }
});
