// eslint-disable-next-line import/order
import { GetterTree } from 'vuex';
import {
  IRule,
  IScanGeohashCount,
  IScansOverTimeCountryScans,
  IScansOverTimeReportItem
} from '@/modules/brand-protection/brand-protection.interface';
import { IRootState } from '@/modules/common/common.interface';
import { BrandProtectionState } from './types';

// eslint-disable-next-line import/prefer-default-export
export const getters: GetterTree<BrandProtectionState, IRootState> = {
  brand_protection_rules(state): IRule[] {
    return state.brand_protection_rules;
  },
  scans_over_time_report(state): IScansOverTimeReportItem[] {
    return state.scans_over_time_report;
  },
  scan_over_time_locations(state): IScanGeohashCount {
    return state.scan_over_time_locations;
  },
  scans_over_time_countries(state): IScansOverTimeCountryScans[] {
    return state.scans_over_time_countries;
  }
};
