import { IRootState } from '@/modules/common/common.interface';
import { Module } from 'vuex';
import { actions } from './actions';
import { getters } from './getters';
import { mutations } from './mutations';
import { IUserState } from './types';

const state: IUserState = {
  paginated_users: null,
  selected_user: null,
  updated_selected_user: null,
  update_selected_profile_image: null,
  selected_company_list: null,
  update_user_allowance: null
};

// eslint-disable-next-line import/prefer-default-export
export const user: Module<IUserState, IRootState> = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
