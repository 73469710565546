import { ICreateShortUrlApi } from '@/views/private/url-shortener/types/interface';
import { ILinkShortUrl } from '@/modules/qr-generator/link.interface';
import ClientInterface from './common/ClientInterface';
import ResourceResponse from './common/ResourceResponse';

export default class ShortUrl {
  private readonly client: ClientInterface;

  private readonly resource: string;

  constructor(client: ClientInterface) {
    this.client = client;
    this.resource = 'link/short-url';
  }

  createShortUrl(body: ICreateShortUrlApi): Promise<ResourceResponse<ILinkShortUrl>> {
    return this.client.create<ICreateShortUrlApi, ILinkShortUrl>(this.resource, body);
  }

  getShortUrlById(id: string): Promise<ResourceResponse<ILinkShortUrl>> {
    return this.client.getById(this.resource, id);
  }

  updateShortUrl(
    id: string,
    body: Partial<ICreateShortUrlApi>
  ): Promise<ResourceResponse<ILinkShortUrl>> {
    return this.client.patch(this.resource, body, id);
  }

  deleteShortUrlById(id: string): Promise<void> {
    return this.client.delete(this.resource, id);
  }
}
