import { ResourceResponse } from '@/services/api/common/Index';
import { MutationTree } from 'vuex';
import { AuthenticationState } from './types';

// eslint-disable-next-line import/prefer-default-export
export const mutations: MutationTree<AuthenticationState> = {
  REFRESHED_AUTH_TOKEN(state: AuthenticationState, refreshed_auth_token: ResourceResponse<void>) {
    state.refreshed_auth_token = refreshed_auth_token;
  },
  UPDATE_DEFAULT_COMPANY_ID(state: AuthenticationState, default_company_id: string) {
    state.default_company_id = default_company_id;
  },
  UPDATE_DEFAULT_COMPANY_JWT(state: AuthenticationState, is_default_company_jwt: boolean) {
    state.is_default_company_jwt = is_default_company_jwt;
  }
};
