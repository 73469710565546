import { IRootState } from '@/modules/common/common.interface';
import { Module } from 'vuex';
import { ICampaignState } from './types';
import { actions } from './actions';
import { mutations } from './mutations';

const state: ICampaignState = {
  paginated_campaign: null,
  selected_campaign: null,
  update_selected_campaign: null
};

// eslint-disable-next-line import/prefer-default-export
export const campaign: Module<ICampaignState, IRootState> = {
  namespaced: true,
  state,
  mutations,
  actions
};
