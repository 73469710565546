import { INotification } from '@/modules/notification/notification.interface';
import { MutationTree } from 'vuex';
import { INotificationState } from './types';

// eslint-disable-next-line import/prefer-default-export
export const mutations: MutationTree<INotificationState> = {
  UPDATE_NOTIFICATIONS(state: INotificationState, all_notifications: INotification[]) {
    state.all_notifications = all_notifications;
  },
  UPDATE_NOTIFICATIONS_VIA_WEB_SOCKET(state: INotificationState, notification: INotification) {
    state.all_notifications.unshift(notification);
  },
  UPDATE_SELECTED_NOTIFICATION(state: INotificationState, selected_notification: INotification) {
    state.selected_notification = selected_notification;
  },
  DEFAULT_UNREAD_NOTIFICATIONS_SWITCH(state: INotificationState, value: boolean) {
    state.show_unread_notifications = value;
  },
  SHOW_NOTIFICATION_MENU(state: INotificationState, show_notification_menu: boolean) {
    state.show_notification_menu = show_notification_menu;
  }
};
