export enum EDimensions {
  AGE = 'age',
  AGE_GENDER = 'ageGender',
  BROWSER = 'browser',
  CAMPAIGN_SCANS = 'campaign',
  CITY = 'city',
  COUNTRY = 'country',
  DEVICE_BRAND = 'deviceBrand',
  DEVICE_MODEL = 'deviceModel',
  GENDER = 'gender',
  OS = 'os',
  PRODUCT_SCANS = 'product',
  AUTH_STATE = 'authState'
}

export enum EMetrics {
  VISITORS = 'visitors',
  COUNTRIES = 'countries',
  CITIES = 'cities',
  BROWSERS = 'browsers',
  OS = 'os',
  DEVICE_BRANDS = 'deviceBrands',
  DEVICE_MODELS = 'deviceModels'
}
